/*******************************************************************************
 * Styles for the base elements of the theme.
 ******************************************************************************/

// Typography

p, h1, h2, h3, h4, h5, h6, em, div, li, span, strong {
  color: var(--global-text-color);
}

hr {
  border-top: 1px solid var(--global-divider-color);
}

table {
  td, th {
    color: var(--global-text-color);
  }
  td {
    font-size: 1rem;
  }
}

a, table.table a {
  color: var(--global-theme-color);
  &:hover {
    color: var(--global-theme-color);
    text-decoration: underline;
  }
  &:hover:after :not(.nav-item.dropdown) {
    width: 100%;
  }
}

figure, img {
  max-width: 90vw;
}

blockquote {
  background: var(--global-bg-color);
  border-left: 2px solid var(--global-theme-color);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  font-size: 1.2rem;
}

// Math

.equation {
  margin-bottom: 1rem;
  text-align: center;
}

// Caption

.caption {
  font-size: 0.875rem;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

// Card

.card {
  background-color: var(--global-card-bg-color);

  img {
    width: 100%;
  }

  .card-title {
    color: var(--global-text-color);
  }

  .card-item {
    width: auto;
    margin-bottom: 10px;

    .row {
      display: flex;
      align-items: center;
    }
  }
}

.text-limit {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Citation

.citation, .citation-number {
  color: var(--global-theme-color);
}

// Profile

.profile {
  margin-left: 1rem;
  width: 100%;

  .address {
    margin-bottom: 5px;
    margin-top: 5px;
    font-family: monospace;
    p {
      display: inline-block;
      margin: 0;
    }
  }
}

@media (min-width: 576px) {
  .profile {
    width: 30%;
    .address {
      p { display: block; }
    }
  }
}

.post-description {
  margin-bottom: 2rem;
  font-size: 0.875rem;
  a {
    color: inherit;
    &:hover {
      color: var(--global-theme-color);
      text-decoration: none;
    }
  }
}


// Navbar customization

.navbar {
  box-shadow: none;
  border-bottom: 1px solid var(--global-divider-color);
  background-color: var(--global-navbar-color);
  opacity: 0.95;
}
.navbar .dropdown-menu {
  background-color: var(--global-navbar-color);
  border: 1px solid var(--global-divider-color);
  a:not(.active) {
    color: var(--global-text-color);
  }
  a:hover {
    color: var(--global-hover-color);
  }
  .dropdown-divider {
    border-top: 1px solid var(--global-divider-color) !important;
  }
}
.dropdown-item {
  color: var(--global-text-color);
    &:hover {
      color: var(--global-hover-color);
      background-color: var(--global-bg-color);
    }
}
.navbar.navbar-light {
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .navbar-brand {
    color: var(--global-text-color);
  }
  .navbar-nav .nav-item .nav-link {
    color: var(--global-text-color);
    &:hover {
      color: var(--global-hover-color);
    }
  }
  .navbar-nav .nav-item.active>.nav-link {
      background-color: inherit;
      font-weight: bolder;
      color: var(--global-theme-color);
      &:hover {
        color: var(--global-hover-color);
      }
  }
  .navbar-brand.social {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 1.7rem;
    a {
      i::before {
        color: var(--global-text-color);
        transition-property: all 0.2s ease-in-out;
      }
      &:hover {
        i::before {
          color: var(--global-theme-color);
        }
      }
    }
  }
}

.navbar-toggler {
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    background-color: var(--global-text-color);
    border-radius: 1px;
    margin-bottom: 4px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }
}

.navbar-toggler.collapsed {
  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}

.light-toggle {
  padding: 0;
  border: 0;
  background-color: inherit;
  color: var(--global-text-color);
  &:hover {
    color: var(--global-hover-color);
  }
}

// Social (bottom)

.social {
  text-align: center;
  .contact-icons {
    font-size: 4rem;
    a {
      i::before {
        color: var(--global-text-color);
        transition-property: all 0.2s ease-in-out;
      }
      &:hover {
        i::before {
          color: var(--global-theme-color);
        }
      }
    }
  }
  .contact-note {
    font-size: 0.8rem;
  }
}


// Footer
footer.fixed-bottom {
  background-color: var(--global-footer-bg-color);
  font-size: 0.75rem;
  .container {
    color: var(--global-footer-text-color);
    padding-top: 9px;
    padding-bottom: 8px;
  }
  a {
    color: var(--global-footer-link-color);
    &:hover {
      color: var(--global-theme-color);
      text-decoration: none;
    }
  }
}

footer.sticky-bottom {
  border-top: 1px solid var(--global-divider-color);
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 0.9rem;
}

// CV

.cv {
  margin-bottom: 40px;
  
  .card {
    background-color: var(--global-card-bg-color);
    border: 1px solid var(--global-divider-color);
    
    .list-group-item {
      background-color: inherit;

      .badge {
        color: var(--global-card-bg-color) !important;
        background-color: var(--global-theme-color) !important;
      }
    }
  }
}

// Blog

.header-bar {
  border-bottom: 1px solid var(--global-divider-color);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 5rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 5rem;
  }
}

.post-list {
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  li {
    border-bottom: 1px solid var(--global-divider-color);
    list-style: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .post-meta {
      color: var(--global-text-color-light);
      font-size: 0.875rem;
      margin-bottom: 0;
    }
    .post-tags {
      color: var(--global-text-color-light);
      font-size: 0.875rem;
      padding-top: 0.25rem;
      padding-bottom: 0;
    }
    a {
      color: var(--global-text-color);
      text-decoration: none;
      &:hover {
        color: var(--global-theme-color);
      }
    }
  }
}

.pagination {
  .page-item {
    .page-link {
      color: var(--global-text-color);
      &:hover {
        color: $black-color;
      }
    }
    &.active .page-link {
      color: $white-color;
      background-color: var(--global-theme-color);
      &:hover {
        background-color: var(--global-theme-color);
      }
    }
  }
}


// Distill

.distill {
  a:hover {
    border-bottom-color: var(--global-theme-color);
    text-decoration: none;
  }
}


// Projects

.projects {
  a {
    text-decoration: none;

    &:hover {
      .card-title {
        color: var(--global-theme-color);
        transition: color 0.5s;
      }
    }
  }

  .card {
    img {
      width: 100%;
    }
  }

  .card-item {
    width: auto;
    margin-bottom: 10px;

    .row {
      display: flex;
      align-items: center;
    }
  }

  .grid-sizer, .grid-item {
    width: 250px;
    margin-bottom: 10px;
  }

  h2.category {
    color: var(--global-divider-color);
    border-bottom: 1px solid var(--global-divider-color);
    padding-top: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: right;
  }
}


// Publications

.publications {
  margin-top: 2rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 2rem;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h2 {
    margin-bottom: 1rem;
    span {
      font-size: 1.5rem;
    }
  }
  h2.year {
    color: var(--global-divider-color);
    border-bottom: 1px solid var(--global-divider-color);
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  ol.bibliography {
    list-style: none;
    padding: 0;
    margin-top: 0;

    li {
      margin-bottom: 1rem;
      .abbr {
        height: 2rem;
        margin-bottom: 0.5rem;
        abbr {
          display: inline-block;
          background-color: var(--global-theme-color);
          padding-left: 1rem;
          padding-right: 1rem;
          a {
            color: white;
            &:hover {
              text-decoration: none;
            }
          }
        }
        .award {
          color: var(--global-theme-color) !important;
          border: 1px solid var(--global-theme-color);
        }
      }
      .title {
        font-weight: bolder;
      }
      .author {
        a {
          border-bottom: 1px dashed var(--global-theme-color);
          &:hover {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
        > em {
          border-bottom: 1px solid;
          font-style: normal;
        }
      }
      .links {
        a.btn {
          color: var(--global-text-color);
          border: 1px solid var(--global-text-color);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          &:hover {
            color: var(--global-theme-color);
            border-color: var(--global-theme-color);
          }
        }
      }
      .hidden {
        font-size: 0.875rem;
        max-height: 0px;
        overflow: hidden;
        text-align: justify;
        transition-property: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;

        p {
          line-height: 1.4em;
          margin: 10px;
        }
        pre {
          font-size: 1em;
          line-height: 1.4em;
          padding: 10px;
        }
      }
      .hidden.open {
        max-height: 100em;
        transition-property: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;
      }
      div.abstract.hidden {
        border: dashed 1px var(--global-bg-color);
      }
      div.abstract.hidden.open {
        border-color: var(--global-text-color);
      }
    }
  }
}

// Rouge Color Customization
figure.highlight {
  margin: 0 0 1rem;
}

pre {
  color: var(--global-theme-color);
  background-color: var(--global-code-bg-color);
  border-radius: 6px;
  padding: 6px 12px;
  pre, code {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }
}

code {
  color: var(--global-theme-color);
  background-color: var(--global-code-bg-color);
  border-radius: 3px;
  padding: 3px 3px;
}


// Transitioning Themes
html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

// Extra Markdown style (post Customization)
.post{
  .post-meta{
    color: var(--global-text-color-light);
    font-size: 0.875rem;
    margin-bottom: 0;
  }
  .post-tags{
    color: var(--global-text-color-light);
    font-size: 0.875rem;
    padding-top: 0.25rem;
    padding-bottom: 1rem;
    a {
      color: var(--global-text-color-light);
      text-decoration: none;
      &:hover {
        color: var(--global-theme-color);
      }
    }
  }
  .post-content{
    blockquote {
      border-left: 5px solid var(--global-theme-color);
      padding: 8px;
    }
  }
}

.embed-responsive-3by2 {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 66.6667%; /* 3:2 aspect ratio */
}

.embed-responsive-3by2 .embed-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-5by7 {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 140%; /* 5:7 aspect ratio */
}

.embed-responsive-5by7 .embed-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-us-letter {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 129.4%; /* Aspect ratio for 8.5:11 */
}

.embed-responsive-us-letter .embed-responsive-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
 }
 
 .carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
 }

.flickity-prev-next-button {
  width: 30px; /* Set smaller width */
  height: 30px; /* Set smaller height */
  border-radius: 5px;
}

* { box-sizing: border-box; }

.carousel {
  background: #222;
}

.carousel-cell {
  width: 100%; /* Ensure cells take full width */
  height: 60vh; /* Adaptive height */
  margin-right: 10px; /* Maintain spacing between cells */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0; /* Prevent shrinking of cells */
  box-sizing: border-box; /* Avoid padding affecting dimensions */
}


.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}

.carousel-cell-image {
  display: block;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover; /* Prevent distortion */
  width: auto; /* Reset width for consistent scaling */
  height: auto; /* Reset height for consistent scaling */
}

.carousel.is-fullscreen .carousel-cell {
  height: 100%; /* Fullscreen height for fullscreen mode */
}

.carousel {
  background: var(--global-bg-color);
}

/* fade in lazy loaded image */
.carousel-cell-image.flickity-lazyloaded,
.carousel-cell-image.flickity-lazyerror {
  opacity: 1;
}

.pdf-outline {
  border: 4px solid var(--global-pdf-color);
}